import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {getUser} from './assets/js/get-user'
import { createHead } from '@vueuse/head'
const head = createHead()
getUser().then(()=> {
    createApp(App).use(head).use(router).mount('#app')
    window.main_url = 'https://admin.becleopatra.com/api/users'
})

